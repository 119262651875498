@import "_generated-font-mixins.scss";
.control {
  box-sizing: border-box;
  width: 100%;
  padding: 20px;

  background-color: #fafafa;
  border-radius: 4px;

  &__title {
    margin-bottom: 12px;
    font-size: 15px;
    line-height: 17px;
    color: #333;
  }

  &__desc {
    font-size: 13px;
    line-height: 15px;
    color: #333;
    opacity: 0.8;
  }

  &__button {
    &__wrapper {
      display: flex;
      margin-bottom: 20px;
      font-size: 10px;
      line-height: 11px;
    }

    &__left {
      padding: 5px 13px;

      color: #333;

      background-color: #fff;
      border: 1px solid #ccc;
      border-right: none;
      border-radius: 4px 0 0 4px;

      &:hover {
        color: #fff;
        background-color: #658dbb;
      }

      &__mobile {
        padding: 8px 16px;

        font-size: 12px;
        color: #333;

        background-color: #fff;
        border: 1px solid #ccc;
        border-right: none;
        border-radius: 4px 0 0 4px;
      }
    }

    &__right {
      padding: 5px 13px;

      color: #333;

      background-color: #fff;
      border: 1px solid #ccc;
      border-left: none;
      border-radius: 0 4px 4px 0;

      &:hover {
        color: #fff;
        background-color: #658dbb;
      }

      &__mobile {
        padding: 8px 16px;

        font-size: 12px;
        color: #333;

        background-color: #fff;
        border: 1px solid #ccc;
        border-left: none;
        border-radius: 0 4px 4px 0;
      }
    }
  }
}

.active {
  color: #fff;
  background-color: #005cc3;
  border-color: #005cc3;

  &:hover {
    color: #fff;
    background-color: #005cc3;
    border-color: #005cc3;
  }
}
